<template>
  <div class="ctitReport">
    <!-- <div class="main-Title bgff"><h2>Block Report</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="80px" size="mini">
          <el-row type="flex" justify="start" style="flex-wrap: wrap; flex-direction: row">
            <el-col :span="6" style="height: 34px">
              <el-form-item label="Date:" label-width="40px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-date-picker
                      v-model="filter.date"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="Start Date"
                      end-placeholder="End Date"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :clearable="false"
                      class="w100"
                      :picker-options="pickerOptions"
                    ></el-date-picker>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="Hour" label-width="45px" class="mb5">
                <el-row>
                  <el-col :span="12" class="pr5">
                    <el-time-select
                      class="w100"
                      v-model="filter.fromHour"
                      :editable="false"
                      :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00',
                      }"
                      placeholder="Select Start Time"
                    ></el-time-select>
                  </el-col>
                  <el-col :span="12" class="pl5">
                    <el-time-select
                      class="w100"
                      v-model="filter.toHour"
                      :editable="false"
                      :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00',
                      }"
                      placeholder="Select End Time"
                    ></el-time-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4" style="height: 34px;">
              <el-form-item label="Interval:" class="mb5">
                <el-checkbox-group v-model="filter.columns">
                  <el-checkbox label="date">Date</el-checkbox>
                  <el-checkbox label="hour">Hour</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="8">
              <el-form-item label="TimeZone:" class="mb5">
                <el-select v-model="filter.timezone" class="w100">
                  <el-option
                    v-for="item in options.timezone"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Offer(s):" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Affiliate(s):" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.affiliateIds"
                      :data-source="affiliatesGroupList"
                      class="w100"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      placeholder="Please select Affiliate"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      style="color: #fff"
                      label="affiliate_id"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="5">
              <el-form-item label="Aff_sub(s):" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-input
                      v-model="filter.affSubs"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="aff_sub"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="Countries(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox v-model="filterCheckedStatus" label="country" class="color-white"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Package(s)" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <!--                    <el-select
                      v-model="filter.pkgs"
                      multiple
                      filterable
                      collapse-tags
                      clearable
                      class="w100"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    >
                      <el-option
                        v-for="item in prods"
                        :key="item"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>-->
                    <el-input
                      v-model="filter.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="prod"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="4" :xs="24" :sm="24" :md="6" :lg="6" :xl="6" style="max-height: 35px;">
              <el-form-item label="Pid(s)" label-width="50px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="21" :md="22" :lg="22" :xl="22">
                    <el-select
                      v-model="filter.pids"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option v-for="item in pidList" :key="item" :label="item" :value="item">
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="4" :sm="3" :md="2" :lg="2" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pid"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="6">
              <el-form-item label="Source(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="22">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      placeholder="请选择"
                      class="w100"
                      size="mini"
                      collapse-tags
                    />
                  </el-col>
                  <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="source_id"
                      >.</el-checkbox
                    >
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button
                  type="primary"
                  :loading="loading.search"
                  @click="filterDataSet('Search', 1)"
                  >Search</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="ctitReportTableRef"
          style="width: 100%"
          size="mini"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
        >
          <el-table-column
            v-if="filter.columns.indexOf('date') > -1 || filter.columns.indexOf('hour') > -1"
            label="Date"
            prop="date"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
          </el-table-column>
          <el-table-column
            v-if="
              filterCheckedStatus.indexOf('offer_id') > -1 ||
                filterCheckedStatus.indexOf('offer_id') > -1
            "
            label="Offer ID"
            prop="offerId"
            fixed
          >
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{ scope.row.offerId }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="
              filterCheckedStatus.indexOf('offer_id') > -1 ||
                filterCheckedStatus.indexOf('offer_id') > -1
            "
            label="Offer Name"
            prop="offerName"
            fixed
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span @click="detailClick(scope.row)" class="cor337ab7">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="filterCheckedStatus.indexOf('affiliate_id') > -1"
            label="affiliate Id"
            prop="affiliateId"
          ></el-table-column>
          <el-table-column
            v-if="filterCheckedStatus.indexOf('affiliate_id') > -1"
            label="affiliate Name"
            prop="affiliateName"
          ></el-table-column>

          <el-table-column
            v-if="filterCheckedStatus.indexOf('aff_sub') > -1"
            label="Aff_sub"
            prop="affSub"
          ></el-table-column>

          <el-table-column
            v-if="filterCheckedStatus.indexOf('country') > -1"
            label="countries"
            prop="country"
          ></el-table-column>
          <el-table-column
            v-if="filterCheckedStatus.indexOf('prod') > -1"
            label="Package"
            prop="prod"
          ></el-table-column>

          <el-table-column
            v-if="filterCheckedStatus.indexOf('pid') > -1"
            label="Pid"
            prop="pid"
          ></el-table-column>

          <el-table-column
            v-if="filterCheckedStatus.indexOf('source_id') > -1"
            label="source"
            prop="sourceId"
          ></el-table-column>
          <el-table-column
            v-if="filterCheckedStatus.indexOf('source_id') > -1"
            label="source Name"
            prop="sourceName"
          ></el-table-column>

          <el-table-column
            label="Install"
            prop="installCount"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
          </el-table-column>
          <el-table-column
            label="CTIT < 30s"
            prop="proportion30"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion30 +
                  ' [' +
                  Math.round(scope.row.proportion30 * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 5min"
            prop="proportion5Minutes"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion5Minutes +
                  ' [' +
                  Math.round(scope.row.proportion5Minutes * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 30min"
            prop="proportion30Minutes"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion30Minutes +
                  ' [' +
                  Math.round(scope.row.proportion30Minutes * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 1h"
            prop="proportion1Hours"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion1Hours +
                  ' [' +
                  Math.round(scope.row.proportion1Hours * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 2h"
            prop="proportion2Hours"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion2Hours +
                  ' [' +
                  Math.round(scope.row.proportion2Hours * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 24h"
            prop="proportion24Hours"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion24Hours +
                  ' [' +
                  Math.round(scope.row.proportion24Hours * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 48h"
            prop="proportion48Hours"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion48Hours +
                  ' [' +
                  Math.round(scope.row.proportion48Hours * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
          <el-table-column
            label="CTIT < 72h"
            prop="proportion72Hours"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{
                scope.row.proportion72Hours +
                  ' [' +
                  Math.round(scope.row.proportion72Hours * scope.row.installCount)
              }}]
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="ctit pagePanel"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import BlockReportCon from '../controllers/report/CTITReport';
  export default {
    name: 'CTITReport',
    ...BlockReportCon,
  };
</script>

<style lang="scss" scoped></style>
